.doctorloan-auth {
    img {
        width: 100%;
    }

    .logo-bg-auth {
        width: 200px;
        margin-bottom: 30px;
    }
}

.dashboard-image {
    img {
        width: 100%;
    }

    .logo-bg-auth {
        width: 200px;
    }
}