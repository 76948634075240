//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap color system
$white: #ffffff;

// Theme colors
// Primary
$primary: #3B71CA;
$primary-active: #386BC0;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: $white;

// Success
$success: #14A44D;
$success-active: #139C49;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: $white;

// Info
$info: #54B4D3;
$info-active: #50ABC8;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: $white;

// Danger
$danger: #DC4C64;
$danger-active: #D1485F;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: $white;

// Warning
$warning: #E4A11B;
$warning-active: #d9991a;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: $white;